<template>
    <div class="page-wrapper section-space--inner--120">
        <!--Projects section start-->
        <div class="project-section">
            <div class="container">
                <div class="row">

                    <div class="col-12 section-space--bottom--40">
                        <div class="project-image" v-for="project in singleProject" :key="project.id">
                            <img :src="`/${ project.image }`" class="img-fluid" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-4 col-12 section-space--bottom--30">
                        <div class="project-information">
                            <h3>{{ data.projectDetails.title }}</h3>
                            <ul v-for="(mainLists, index) in singleProject" :key="index">
                                <li v-for="(sublist, listIndex) in mainLists.lists" :key="listIndex">
                                    <strong>{{ sublist.label }}</strong>{{ sublist.desc }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                        <div class="project-details">
                            <h2 v-for="project in singleProject" :key="project.id">{{ project.title }}</h2>
                            <p v-for="project in singleProject" :key="project.id">{{ project.desc }}</p>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="row row-5 image-popup" v-for="(project, index) in singleProject" :key="index">
                            <div class="col-xl-3 col-lg-4 col-sm-6 col-12 section-space--top--10" v-for="(img, imageIndex) in project.gallery" :key="imageIndex">
                                <div class="gallery-item single-gallery-thumb">
                                    <!-- <img :src="`/${ img.image }`" class="img-fluid" alt="thumbnail"> -->
                                    <!-- <lightbox cells="4" :items="`/${ img.image }`"></lightbox> -->
                                    <!-- <span class="plus"></span> -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
                                    <img class="image img-fluid d-block mx-auto rounded" v-for="(image, i) in imgs" :src="image" :key="i" @click="index = i">
                                    <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
        </div>
        <!--Projects section end-->
    </div>
</template>
<script>
// import VueGallerySlideshow from 'vue-gallery-slideshow'
import data from '../../data/project.json'

export default {
    components: {
        VueGallerySlideshow
    },
    data () {
        return {
            data,
            singleProject: [],
            images: [
              'https://placekitten.com/801/800',
              'https://placekitten.com/802/800',
              'https://placekitten.com/803/800',
              'https://placekitten.com/804/800',
              'https://placekitten.com/805/800',
              'https://placekitten.com/806/800',
              'https://placekitten.com/807/800',
              'https://placekitten.com/808/800',
              'https://placekitten.com/809/800',
              'https://placekitten.com/810/800'
            ],
            imgs: [
              'https://placekitten.com/801/800',
            ],
            index: null
        }
    },
    computed: {
        id() {
            return this.$route.params.id
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            let data = this.data.projectDetails.projectDetailsMain
            return this.singleProject = data.filter(item => {
                return item.id == this.id
            })
        }
    }
};
</script>